
// react plugin for creating vector maps
import { VectorMap } from "react-jvectormap";

// reactstrap components
import { Card, CardBody, Container, Row } from "reactstrap";
// core components
import AlternativeHeader from "components/Headers/AlternativeHeader.js";

let mapData = {
  // AU: 760,
  // BR: 550,
  // CA: 120,
  // DE: 1300,
  // FR: 540,
  // GB: 690,
  // GE: 200,
  // IN: 200,
  // RO: 600,
  // RU: 300,
  TW: 1,
  US: 4,
};

function Vector() {
  return (
    <>
      <AlternativeHeader name="Vector maps" parentName="Maps" />
      <Container className="mt--6" fluid>
        <Row>
          <div className="col">
            <Card>
              <CardBody className="pt-0">
                <VectorMap
                  containerClassName="vector-map"
                  containerStyle={{
                    width: "100%",
                    height: "600px",
                  }}
                  map={"world_mill"}
                  zoomOnScroll={false}
                  scaleColors={["#f00", "#0071A4"]}
                  normalizeFunction="polynomial"
                  hoverOpacity={0.7}
                  hoverColor={false}
                  backgroundColor="transparent"
                  regionStyle={{
                    initial: {
                      fill: "#e9ecef",
                      "fill-opacity": 0.8,
                      stroke: "none",
                      "stroke-width": 0,
                      "stroke-opacity": 1,
                    },
                    hover: {
                      fill: "#dee2e6",
                      "fill-opacity": 0.8,
                      cursor: "pointer",
                    },
                    selected: {
                      fill: "yellow",
                    },
                    selectedHover: {},
                  }}
                  markerStyle={{
                    initial: {
                      fill: "#fb6340",
                      "stroke-width": 0,
                    },
                    hover: {
                      fill: "#11cdef",
                      "stroke-width": 0,
                    },
                  }}
                  markers={[
                    {
                      latLng: [29.7604, -95.3698],
                      name: "Houston",
                    },
                    {
                      latLng: [27.9506, -82.4572],
                      name: "Tampa",
                    },
                    {
                      latLng: [23.9911, 121.6112],
                      name: "Hualien",
                    },
                    {
                      latLng: [40.7128, -74.0060],
                      name: "New York",
                    },
                    {
                      latLng: [32.7767, -96.797],
                      name: "Dallas",
                    },
                    {
                      latLng: [36.1699, -115.1398],
                      name: "Las Vegas",
                    },
                    {
                      latLng: [34.0522, -118.2437],
                      name: "Los Angeles",
                    }
                  ]}
                  series={{
                    regions: [
                      {
                        values: mapData,
                        scale: ["#ced4da", "#adb5bd"],
                        normalizeFunction: "polynomial",
                      },
                    ],
                  }}
                />
              </CardBody>
            </Card>
          </div>
        </Row>
      </Container>
    </>
  );
}

export default Vector;
